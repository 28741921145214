import React, { useEffect, useState } from 'react'
import { useParams,useLocation } from 'react-router-dom'
 

const ArticleDetails = () => {
    const location = useLocation();
    const [iframeLink, setIframeLink] = useState("")
    useEffect(() => {
      setIframeLink(location.state?.link)
    },[])
    

    console.log(location);


    return  (
    <div>
    <iframe src= {iframeLink} width="100%" height="900px"/>
    </div>

    ) 


}

export default ArticleDetails
