import React from 'react';
import "./Settings.css";

export default function Settings() {
    return (
        <>
		<div className="m-3 p-1">
            <h5 style={{marginLeft:"40px"}}>
			{"Settings"}
			</h5>
		</div>
            <ul className="settings container px-5" >
                <li className="d-flex justify-content-between border-bottom">
                    <h6 style={{ fontWeight: "700" }}>Therapy Exclusive: </h6>
                    <span className="custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input" id="switch1" />
                        <label for="switch1" className="custom-control-label"></label>
                    </span>
                </li>

                <div>
                    <h6 className="border-bottom1 pb-1">Academic Intrest: </h6>
                    <ul className="border-bottom">
                        <li className="d-flex justify-content-between">
                            <p>Journal Updates:</p>
                            <span className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" id="switch2" />
                                <label for="switch2" className="custom-control-label"></label>
                            </span>
                        </li>
                        <li className="d-flex justify-content-between">
                            <p>Conference Updates:</p>
                            <span className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" id="switch3" />
                                <label for="switch3" className="custom-control-label"></label>
                            </span>
                        </li>
                        <li className="d-flex justify-content-between">
                            <p>Medical Magazines:</p>
                            <span className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" id="switch4" />
                                <label for="switch4" className="custom-control-label"></label>
                            </span>
                        </li>
                        <li className="d-flex justify-content-between">
                            <p>New Molucules:</p>
                            <span className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" id="switch5" />
                                <label for="switch5" className="custom-control-label"></label>
                            </span>
                        </li>
                        <li className="d-flex justify-content-between">
                            <p>Medical Technology:</p>
                            <span className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" id="switch6" />
                                <label for="switch6" className="custom-control-label"></label>
                            </span>
                        </li>
                    </ul>
                </div>

                <li className="d-flex justify-content-between border-bottom">
                    <h6>Hospital Management: </h6>
                    <span className="cl-2 pl-5 custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input" id="switch7" />
                        <label for="switch7" className="custom-control-label"></label>
                    </span>
                </li>

                <li className="d-flex justify-content-between border-bottom">
                    <h6>Health & Adventure: </h6>
                    <span className="cl-2 pl-5 custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input" id="switch8" />
                        <label for="switch8" className="custom-control-label"></label>
                    </span>
                </li>

                <li className="d-flex justify-content-between border-bottom">
                    <h6>Food, Cooking & Recipes: </h6>
                    <span className="cl-2 pl-5 custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input" id="switch9" />
                        <label for="switch9" className="custom-control-label"></label>
                    </span>
                </li>

                <li className="d-flex justify-content-between border-bottom">
                    <h6>Financial Management: </h6>
                    <span className="custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input" id="switch10" />
                        <label for="switch10" className="custom-control-label"></label>
                    </span>
                </li>

                <li className="d-flex justify-content-between border-bottom">
                    <h6>Social Updates: </h6>
                    <span className="custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input" id="switch11" />
                        <label for="switch11" className="custom-control-label"></label>
                    </span>
                </li>

                <div className="border-bottom">
                    <h6>Entertainment: </h6>
                    <ul>
                        <li className="d-flex justify-content-between">
                            <p>Sports:</p>
                            <span className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" id="switch12" />
                                <label for="switch12" className="custom-control-label"></label>
                            </span>
                        </li>
                        <li className="d-flex justify-content-between">
                            <p>Music:</p>
                            <span className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" id="switch13" />
                                <label for="switch13" className="custom-control-label"></label>
                            </span>
                        </li>
                        <li className="d-flex justify-content-between">
                            <p>Movies:</p>
                            <span className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" id="switch14" />
                                <label for="switch14" className="custom-control-label"></label>
                            </span>
                        </li>
                    </ul>
                </div>
            </ul>
        </>
    )
}
