import React, { useEffect } from "react";
import "./App.css";
import Home from "./pages/Home/Home";
import Therapies from "./pages/Therapies/Therapies";
import Products from "./pages/Products/Products";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Subcategories from "./pages/Subcategories/Subcategories";
import Login from "./pages/Login/Login";
import Header from "./components/Header/Header";
import Verify from "./pages/Verify/Verify";
import Files from "./pages/Files/Files";
import Articles from "./pages/Articles/Articles";
import Profile from "./pages/Pofile/Profile";
import Contact from "./pages/Contact/Contact";
import Emailer from "./pages/Emailer/Emailer";
import Videos from "./pages/Videos/Videos";
import Portals from "./pages/Portals/Portals";
import BottomNav from "./components/BottomNav/BottomNav";
import EditProfile from "./pages/EditProfile/EditProfile";
import Services from "./pages/Services/Services";
import Privacy from "./pages/Privacy/Privacy";
import Journals from "./pages/Journals/Journals";
import TherapyArticles from "./pages/TherapyArticles/TherapyArticles";
import Viewfile from "./pages/Viewfile/Viewfile";
import SearchedArticles from "./pages/SearchedArticles/SearchedArticles";
import SearchedProducts from "./pages/SearchedProducts/SearchedProducts";
import Settings from "./pages/Settings/Settings";
import ArticleDetails from "./pages/Articles/ArticleDetails";

// analytics
import ReactGA from "react-ga4";
import TagManager from 'react-gtm-module';

function App() {
  useEffect(() => {
    ReactGA.initialize("G-VPT42XDXXK");
  }, []);
const tagManagerArgs = {
  gtmId: 'GTM-5425PQN'
}
TagManager.initialize(tagManagerArgs)

  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={< Login />} />
          <Route path="/verify" element={< Verify />} />
          <Route path="/home" element={< Home />} />
          <Route path="/subcategories/:catId/" element={<Subcategories />} />
		  <Route path="/therapies" element={<Therapies />} />
		  <Route path="/products/:title/" element={<Products />} />
          <Route
            path="/subcategories/:catId/:desc"
            element={<Subcategories />}
          />
          <Route path="/files/:linkName/:subCatTitle" element={<Files />} />
          <Route path="/articles" element={<Articles />} />
          <Route path="/articles/:link" element={<ArticleDetails />} />
          <Route
            path="/searcharticles/:searchedString"
            element={<SearchedArticles />}
          />
		  <Route
            path="/searchproducts/:searchedString"
            element={<SearchedProducts />}
          />
          <Route path="/journals/:journalId" element={<Journals />} />
          <Route
            path="/articles/therapy/:therapy"
            element={<TherapyArticles />}
          />
          <Route path="/profile" element={<Profile />} />
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/contact" element={<Contact />} />
          <Route
            path="/emailer/:subCatTitle/:therapeuticFocus"
            element={<Emailer />}
          />
          <Route
            path="/videos/:linkName/:therapeuticFocus/:subCatTitle"
            element={<Videos />}
          />
          <Route
            path="/portals/:linkName/:therapeuticFocus/:subCatTitle"
            element={<Portals />}
          />
          <Route path="/services" element={<Services />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/privacy" element={<Privacy />} />
		  <Route path="/viewfile/:file_name" element={<Viewfile />} />
        </Routes>
        <BottomNav />
      </BrowserRouter>
    </div>
  );
}

export default App;
