import React from 'react';
import { Link } from "react-router-dom";
import config from '../../config';
import "./CategoriesCard.css";

export default function CategoriesCard({ title, link, catId, desc, banner, outcome }) {

    let span = document.createElement('span');
    span.innerHTML = desc;
	
	//filename.split('.').slice(0, -1).join('.') OR path.parse(filename).name;
	if(outcome == "submenu"){
		if(banner == "Y"){
			return (
		
				<Link to={`/subcategories/${catId}/${span.innerText}`} 
				className={"col-md-9 m-md-9 col-9 mx-auto"} style={{ marginBottom:"10px"}}>
					<div className="catCard mw-50" style={{ margin:"auto", maxWidth:"70%"}} >
						<img src={`${config.CAT_IMG_BASE_URI}/${catId}.jpg`} alt="img" height="auto" width="100%" className="mx-auto m-2" />
					</div>
				</Link >
				
		)
		}else{
			
			return (
		
			<Link to={`/subcategories/${catId}/${span.innerText}`} 
			className={"col-md-3 m-md-1 col-5 mx-auto catCard"}>
				<img src={`${config.CAT_IMG_BASE_URI}/${catId}.png`} alt="img" height="100%" width="100%" className="mx-auto" />
			</Link >
		
			)
		}
	}else if(outcome == "page"){
		if(banner == "Y"){
			return (
		
				<a href={link == "rpgserv_priority_contact.php" ? "/contact" : link}  target="_blank" rel="noreferrer noopener" 
				className={"col-md-9 m-md-9 col-9 mx-auto"} style={{ marginBottom:"10px"}}>
					<div className="catCard mw-50" style={{ margin:"auto", maxWidth:"70%"}} >
						<img src={`${config.CAT_IMG_BASE_URI}/${catId}.jpg`} alt="img" height="auto" width="100%" className="mx-auto m-2" />
					</div>
				</a >
				
		)
		}else{
			
			return (
		
			<a href={link == "rpgserv_priority_contact.php" ? "/contact" :link}  target="_blank" rel="noreferrer noopener"
			className={"col-md-3 m-md-1 col-5 mx-auto catCard"}>
				<img src={`${config.CAT_IMG_BASE_URI}/${catId}.png`} alt="img" height="100%" width="100%" className="mx-auto" />
			</a >
		
			)
		}
	}else{
		return "test";
		
	}
}
//link == "rpgserv_priority_contact.php" ? "/contact" : 