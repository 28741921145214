import React from 'react';
import { Link } from "react-router-dom";
import "./SubCategoriesCard.css";
import config from '../../config';

import Sanitization from '../models/Sanitization';
import ServPersonalizedPrintables from '../models/ServPersonalizedPrintables';
import ServPrintables from '../models/ServPrintables';
import ServPrints from '../models/ServPrints';
import CovidAccessories from '../models/CovidAccessories';
import ServWebinars from '../models/ServWebinars';
import ServPersonalisedVideo from '../models/ServPersonalisedVideo';
import ServTherapySpecific from '../models/ServTherapySpecific';
import ServOnline from '../models/ServOnline';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

export default function SubCategoriesCard({ subCatDetails }) {

    const subCatImgLink = `${config.SUBCAT_IMG_BASE_URI}/${subCatDetails.serv_sub_category_id}.png`
    return (
        <>
            {subCatDetails.sub_header !== "" && <p className="col-12">{subCatDetails.sub_header}</p>}
            {
                subCatDetails.outcome == "modal" ?
                    <Popup trigger={
                        <Link to="" id="subCatCardLink" className="col-md-2 m-md-1 col-5 mx-auto subCatCard card-body d-flex flex-column align-items-center justify-content-around">
                            <p className="text-center" style={{ fontSize: "0.6rem", margin: "-1rem auto -0.1rem auto" }}>{subCatDetails.title}</p>
                            <img src={subCatImgLink} alt="icon" height="50%" />
                        </Link>
                    } modal>

                        {close => (
                            <div className="modal1">
                                <button className="close1" id="closePopup" onClick={close}>
                                    &times;
                                </button>
                                {subCatDetails.table_name !== "serv_webinars" && (
                                <h3 className="header1">{subCatDetails.title}</h3>
                                )}
                                <div className="content1">
                                    {
                                        subCatDetails.table_name === "serv_sanitization" ? <Sanitization subCatDetails={subCatDetails} /> : subCatDetails.table_name === "serv_personalised_printables" ? <ServPersonalizedPrintables subCatDetails={subCatDetails} /> : subCatDetails.table_name === "serv_personalised_vdo" ? <ServPersonalisedVideo subCatDetails={subCatDetails} /> : subCatDetails.table_name === "serv_printables" ? <ServPrintables subCatDetails={subCatDetails} /> : subCatDetails.table_name === "serv_prints" ? <ServPrints subCatDetails={subCatDetails} /> : subCatDetails.table_name === "covid_accessories" ? <CovidAccessories subCatDetails={subCatDetails} /> : subCatDetails.table_name === "serv_webinars" ? <ServWebinars subCatDetails={subCatDetails} /> : subCatDetails.table_name === "serv_online" ? <ServOnline subCatDetails={subCatDetails} /> : subCatDetails.table_name === "serv_therapy_specific" ? <ServTherapySpecific subCatDetails={subCatDetails} /> : ""
                                    }
                                </div>

                            </div>
                        )}
                    </Popup>
					:
					<Link to={subCatDetails.service_type === "portals" || subCatDetails.service_type === "videos" ? `/${subCatDetails.service_type}/${subCatDetails.link_name}/${subCatDetails.therapeutic_focus}/${subCatDetails.title}`
						: subCatDetails.service_type === "emailer" ? `/${subCatDetails.service_type}/${subCatDetails.title}/${subCatDetails.therapeutic_focus}`
							: subCatDetails.service_type === "files" ? `/${subCatDetails.service_type}/${subCatDetails.link_name}/${subCatDetails.title}`
								: `/${subCatDetails.service_type}`} id="subCatCardLink" className="col-md-2 m-md-1 col-5 mx-auto subCatCard card-body d-flex flex-column align-items-center justify-content-center">
						<p className="text-center" style={{ margin: "-1rem auto -0.1rem auto" }}>{subCatDetails.title}</p>
						<img src={subCatImgLink} alt="icon" height="50%" />
					</Link>   
            }
        </>
    )
}