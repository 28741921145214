import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router";
import axios from 'axios';
import config from '../../config';

import Banner from '../../components/Banner/Banner';
import CategoriesCard from '../../components/CategoriesCard/CategoriesCard';
import "./Home.css";
import Modal from 'react-bootstrap/Modal';

//import OneSignal from "react-onesignal";

import { genrateToken, messaging } from '../../Firebase'
import { onMessage } from 'firebase/messaging'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'


const Home = () => {

    const navigate = useNavigate();

    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token !== config.TOKEN) {
            navigate("/");
        }

        // enable the header & bottom navigation bar
        document.getElementById("hideHeader").style.display = "block";
        document.getElementById("hideBottomNav").style.display = "block";
    }, []);

    const [Data, setData] = useState([]);
	const [Popup, setpopup] = useState();
	const [Show, setshow] = useState(true);
	const [popuplink, setPopuplink] = useState('');
	const [notificationSent, setNotificationSent] = useState(false)

    useEffect(() => {
		
		const profileData = JSON.parse(localStorage.getItem("profileData"));
		
		var therapeutic_focus=['Common',profileData.therapeutic_focus];
		
		// firebase 
		 
		
			genrateToken().then(token =>{
				console.log(token)
				let firebaseData = new FormData();
				firebaseData.append("token", token);
				firebaseData.append("topic", therapeutic_focus);
				 
					axios.post(`${config.BASE_API_URI}/firebase_subcribe.php`,firebaseData ).then(res => {
						
						console.log(res)
						
					})
			})
			
			
			
			onMessage(messaging, (payload) => {
			  console.log(payload)
			  if (!notificationSent && Notification.permission === 'granted') {
				toast.info(
				  <div>
					{/* <img
					  src={payload.notification.image}
					  alt="Notification"
					  style={{ width: '50px', height: '50px', marginRight: '10px' }}
					/> */}
					<div className="mx-3">
					  <h3>{payload.notification.title}</h3>
					  <p>{payload.notification.body}</p>
					</div>
				  </div>,
				  {
					autoClose: false, // Disable auto close
				  },
				)
				new Notification(payload.notification.title, { body: payload.notification.body })
				setNotificationSent(true)
			  }
			})
		// firebase notification
		
		
/*
		// push notifications init
		OneSignal.init({

		  appId: "f18066f6-1633-414d-9ede-bcccd4bcf198", //original live RPGLS
		  //appId: "24e377f1-910e-461c-b91d-dfafbecd24b2", //Localhost
		  //appId: "d499a62f-20b3-4ba3-9413-f6e1e7065f55", // live testing icube.net.in
		});
		
		OneSignal.on('subscriptionChange', function(isSubscribed) {
	   
			if (isSubscribed === true) {
			  
				console.log('The user subscription state is now:', isSubscribed);
				
				var pathArray = window.location.pathname.split('/');
				
				OneSignal.sendTags({
					"subscription_page": pathArray[1],
					"doctor": therapeutic_focus,
				}).then(function(tagsSent) {
					// Callback called when tags have finished sending
					console.log(tagsSent);
				});
			}
		});
		
		OneSignal.getTags().then(function(tags) {
              if(tags.doctor !== therapeutic_focus){
				   OneSignal.sendTag('doctor',therapeutic_focus);
				   console.log(tags);
			  }
          });
		*/
        let formData = new FormData();
        formData.append("key", localStorage.getItem("key"));

        axios.post(`${config.BASE_API_URI}/GetCategory.php?therapeutic_focus=${profileData.therapeutic_focus}`, formData).then(res => {
			
            setData(res.data[1]);
			setpopup(res.data[2].banner_id);
			setPopuplink(res.data[3].popuplink);
			
			let profileData = JSON.parse(localStorage.getItem("profileData"));
			const updatedProfileData = {
				  ...profileData,
				  campaign_link: res.data[5].campaign_link,
				  feedback_link: res.data[4].feedback_link			  // Replace 'new focus' with the desired new value
				};
				
				// Update state and localStorage
				localStorage.setItem('profileData', JSON.stringify(updatedProfileData))
				
			
        })
		
		
		
    }, []);
	
	const handleClose = () => setshow(false);
    
	setTimeout(function () { 
        setshow(false) 
    },3000);
	
    return (
        <>
            <div className="row d-flex justify-content-center my-2 mx-auto">
				
                {
                    Data.map(d => {
                        return (
                            <CategoriesCard key={d.serv_category_id} title={d.title} link={d.link_name} catId={d.serv_category_id} desc={d.description} banner={d.banner} outcome={d.outcome}/>
                        )
                    })
                }
				
            </div>	

            <Banner />
			
	        <Modal show={Popup? Show: false} onHide={handleClose} centered className='modal-popup'>
                <Modal.Body className='p-0 m-0'>
				    <button type="button" className="close_btn" onClick={handleClose}>X</button>
					<a href={popuplink} target="blank">
						<img src={`${config.BANNERS_IMG_BASE_URI}/${Popup}.jpg`} alt='img' width="100%" className='rounded-3 pop-img'></img>
					</a>
                </Modal.Body>
            </Modal>
			
        </>
    );
}

export default Home;
