import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getMessaging, getToken } from 'firebase/messaging'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBsFXCPFvvebETxMvWDdfFlXnYcVsNx6WM",
  authDomain: "rpgserv-729b1.firebaseapp.com",
  projectId: "rpgserv-729b1",
  storageBucket: "rpgserv-729b1.appspot.com",
  messagingSenderId: "652084823448",
  appId: "1:652084823448:web:055d52a68a713099d657ed",
  measurementId: "G-06WJYGWZGD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app)
const messaging = getMessaging(app)

const genrateToken = async () => {
  const permission = await Notification.requestPermission()
  console.log(permission)
  if (permission === 'granted') {
    const token = await getToken(messaging, {
      vapidKey:
        'BOWslv6FvaYJPlgKLK2U-lxKMQ04LINYJX7xZhEtAdpvgcmE5zgN7BPE5XnjH16BpuG_2p_Hwv5kZfjlTTM0cSw',
    })
      return token;
    //We can send token to server
    console.log('Token generated : ', token)
  } else if (permission === 'denied') {
    //notifications are blocked
    alert('You denied for the notification')
  }
}

export { auth, genrateToken, messaging }
