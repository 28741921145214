import React, { useState } from 'react'
import axios from 'axios';
import config from '../../config';
import { convertToJson } from '../../helpers/utils';


const Samples = ({ ProductDetails ,dose, qty }) => {
    const [loading,setLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        let formData = new FormData();
        formData.append("key", localStorage.getItem("key"));
        formData.append("message", ProductDetails.title+" samples ordered.");
        formData.append("category", "Samples");
        formData.append("sub_category", ProductDetails.title);
        formData.append("dose", dose);
        formData.append("qty", qty);

        axios.post(`${config.BASE_API_URI}/ServSamples.php`, formData).then(res => {
			
			if(res.data[1].Message == 'Demo Account!'){
				alert(res.data[1].Message)
			}else{
			
            let resObj =  convertToJson(res.data)
            res.data[1].Message ? alert(res.data[1].Message) : alert(resObj.msg);
			}
			
        }).catch(err => alert(err)).finally(() => {
            setLoading(false);
            document.getElementById("closePopup").click();
        });
    }

    return (
        <form>
            <div>
			  {"Order samples of "+ProductDetails.title}
			</div>
            <div className="modal-footer">
                <button type="submit"  disabled ={loading} onClick={handleSubmit} className="btn btn-primary roundBtn">{loading ? "Processing...":"Order Now"}</button>
            </div>
        </form>
    )
}

export default Samples;