import { React , useState } from 'react';
import { Link } from "react-router-dom";
import "./ProductsCard.css";
import config from '../../config';

import Samples from '../models/Samples';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

export default function ProductsCard({ ProductDetails }) {
	
	const [dose, setDose] = useState('')
	const [qty, setqty] = useState('')
	
    const ProductImgLink = `${config.PRODUCT_IMG_BASE_URI}/${ProductDetails.title}.png`
    return (
        <>
            
            {
                    <Popup trigger={
                        <Link to="" id="ProductCardLink" >
						<div className="" style={{ margin:"auto", maxWidth:""}} >
                            <img src={ProductImgLink} alt="icon"  width="100%"/>
						</div>
                        </Link>
                    } modal>

                        {close => (
                            <div className="modal1">
                                <button className="close1" id="closePopup" onClick={close}>
                                    &times;
                                </button>
                                {ProductDetails.table_name !== "serv_webinars" && (
                                <h3 className="header1">{ProductDetails.title}</h3>
								
                                )}
                                <div className="content1">
									<p>{"Composition: "+ProductDetails.composition}</p>
									
									{ProductDetails.dose !== '' && ProductDetails.dose !== null && (
									
										<select value={qty} style={{ float: "right" }} onChange={(e) => setqty(e.target.value)}>
												<option value="1">Sripts Qty</option>
												<option  value='1'>1</option>
												<option  value='2'>2</option>
												<option  value='3'>3</option>
												<option  value='4'>4</option>
												<option  value='5'>5</option>
												<option  value='6'>6</option>
												<option  value='7'>7</option>
												<option  value='8'>8</option>
												<option  value='9'>9</option>
												<option  value='10'>10</option>
											))}
										</select>
									
									)}
									
									{ProductDetails.dose !== '' && ProductDetails.dose !== null && (
									
										<select value={dose} style={{ float: "right" }} onChange={(e) => setDose(e.target.value)}>
											<option value="">Select dose</option>
											{ProductDetails.dose.split(',').map((item, index) => (
												<option key={index} value={item}>
													{item}
												</option>
											))}
										</select>
										
									)}
									

                                    <Samples ProductDetails={ProductDetails} dose={dose} qty={qty} />
                                </div>

                            </div>
                        )}
                    </Popup>
            }
        </>
    )
}