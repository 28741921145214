import React from 'react'
import "./PortalCard.css"

export default function PortalCard({ portalInfo }) {
    return (
        <div className="p-1 ">
            <span>-</span>
            <a href={portalInfo.link} target="_blank"><span className="ml-2">{portalInfo.title}</span></a>
            <hr />
        </div>
    )
}
