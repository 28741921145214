import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router'
import Article from '../../components/Article/Article'
import config from '../../config'

import InfiniteScroll from 'react-infinite-scroll-component';

export default function SearchedArticles() {

    const navigate = useNavigate();

    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token !== config.TOKEN) {
            navigate("/");
        }
    }, []);

    const { searchedString } = useParams();

    const PAGE = 1;
    const [SearchedArticles, setSearchedArticles] = useState([]);
    const [PageNumber, setPageNumber] = useState(PAGE);

    useEffect(() => {
        let formData = new FormData();
        formData.append("key", localStorage.getItem("key"));
        formData.append("search_string", searchedString);
		formData.append("page", PageNumber);

        axios.post(`${config.BASE_API_URI}/SearchArticles.php`, formData).then(res => {
            setSearchedArticles([...SearchedArticles, ...res.data[1].result]);
        })
    }, [searchedString, PageNumber]);

     const fetchMoreData = () => {
         setPageNumber(prev => prev + 1);
     }

    return (
        <>
             <InfiniteScroll
                dataLength={SearchedArticles.length}
                next={fetchMoreData}
                hasMore={true}
                loader={<h4 style={{marginLeft:"40px"}}>Loading...</h4>}
            > 
            {
                SearchedArticles !== [] ? (SearchedArticles.map((article, index) => {
                    return (
                        <Article key={index} article={article} />
                    )
                })) : alert(<p>No Articles Found..!</p>)
            }
             </InfiniteScroll > 
        </>
    )
}
