import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Menubar from '../Menubar/Menubar';
import logo from "../../assets/logo.png";
// import user from "../../assets/icons/user.png";
import "./Header.css";

const Header = () => {

    const navigate = useNavigate();

    const [SearchString, setSearchString] = useState("");

    const [DrName, setDrName] = useState("");
    useEffect(() => {
        let profileData = localStorage.getItem("profileData");
        let drDetails = JSON.parse(profileData);
        setDrName(drDetails ? drDetails.first_name : "");
    }, []);

    const handleSearch = (e) => {
        e.preventDefault();

        setSearchString(e.target.value);

        if (e.keyCode === 13) {
            document.getElementById("searchBtn").click();
        }
    }

    const doSearch = (e) => {
        e.preventDefault();
        navigate(`/searcharticles/${SearchString}`);
    }

    return (
        <div id="hideHeader">
            <div className="headerContainer">
                <div className="m-4 my-1 d-flex justify-content-around align-items-center">
                    <Link to="/">
                        <img src={logo} alt="logo" width="70%" />
                    </Link>

                    <label className='text-center mobileOnly header__label'>{DrName && `Welcome Dr. ${DrName}`}
                        <input type="search" onKeyUp={handleSearch} placeholder="Search" className="form-control border mt-1" id="searchBar" />
                    </label>

                    <input type="search" onKeyUp={handleSearch} placeholder="Search" className="form-control border desktopOnly" id="searchBar" />

                    <button id="searchBtn" style={{ display: "none" }} onClick={doSearch}>Search</button>

                    <p className='text-center mt-2 desktopOnly'>{DrName && `Welcome Dr. ${DrName}`}
                    </p>

                    {/* <Link to="/profile">
                        <img src={user} alt="user" width="30px" height="30px" className="profileIcon" />
                    </Link> */}
                </div>
            </div>

            <hr />
            <div className="">
                <Menubar />
            </div>

        </div>
    );
}

export default Header;
