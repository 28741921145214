import React from 'react';
import "./Menubar.css";

import { Link } from 'react-router-dom';

const Menubar = () => {

    const profileData = localStorage.getItem("profileData");
    const profile = JSON.parse(profileData);

    return (
        <>
            <ul className="navBar d-none d-md-block">
                <span>
                    <Link to="/home">Home</Link>

                    <Link to="/articles">Articles</Link>

                    <Link to="/services">My Services</Link>

                    <Link to="/profile">My Profile</Link>

                    <Link to="/settings">Settings</Link>

                    <Link to="/privacy">Privacy</Link>


                    <Link onClick={() => {
                        localStorage.clear();
                        sessionStorage.clear();
                    }}
                        to="/">Logout</Link>
                </span>

                {/* <span>
                    <Link to="" id="drName">Welcome Dr. {profile && profile.first_name}</Link>
                </span> */}
            </ul>


        </>
    );
}

export default Menubar;
