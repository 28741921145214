import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { useParams } from 'react-router'
import VideoCard from '../../components/VideoCard/VideoCard'
import config from '../../config';

export default function Videos() {

    const navigate = useNavigate();

    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token !== config.TOKEN) {
            navigate("/");
        }
    }, []);

    const { linkName, therapeuticFocus, subCatTitle } = useParams();

    const [Videos, setVideos] = useState([]);

    useEffect(() => {
        axios.post(`${config.BASE_API_URI}/GetVideos.php?key=${localStorage.getItem("key")}&page=${linkName}&therapeutic_focus=${therapeuticFocus}`).then(res => {
            setVideos(res.data[1]);
        })
    }, []);
    return (
        <div className="m-md-4 overflow-hidden">
            <h5 className="mb-3 ml-3 col-12">{subCatTitle}</h5>
            <div className="row d-flex justify-content-between col-12">
                {
                    Videos.map(v => {
                        return (
                            <VideoCard key={v.video_id} title={v.title} trailer={v.trailer_link} fullVideoLink={v.link} videoId={v.video_id} />
                        )
                    })
                }
            </div>
        </div>
    )
}
