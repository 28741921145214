import React from 'react'
import "./ServiceCard.css"

export default function ServiceCard({ orderDetails }) {


    let languageTable = ["serv_printables","serv_personalised_printables","serv_personalised_vdo"];
    let dateTime = ["serv_sanitization","serv_webinars"];

    return (
        <div className="p-1">
            <p style={{ fontWeight: 'bold', marginBottom: "-1px" }}>{orderDetails.sub_category}</p>
            {(languageTable.includes(orderDetails.Table) && orderDetails.Language) && <p>In {orderDetails.Language}</p>}
            {dateTime.includes(orderDetails.Table) && <p>Reqd. On {orderDetails.Date + " " + orderDetails.Time}</p>}
            {orderDetails.Addl_Description && <p>({orderDetails.Addl_Description})</p>}
            { (orderDetails.requested_on) && <span>{orderDetails.requested_on}</span>}
            {orderDetails.status && <span className="float-right text-right mb-1">Status: <b>{orderDetails.status}</b></span>}
            <hr />
        </div >
    )
}
