import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { useParams } from 'react-router'

import EmailerCard from '../../components/EmailerCard/EmailerCard'
import config from '../../config';

export default function Emailer() {

    const navigate = useNavigate();

    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token !== config.TOKEN) {
            navigate("/");
        }
    }, []);

    const { subCatTitle, therapeuticFocus } = useParams();
    const [Emailers, setEmailers] = useState([]);

    useEffect(() => {
        const key = localStorage.getItem("key");
        axios.post(`${config.BASE_API_URI}/GetEmailers.php?key=${key}&subcategory_title=${subCatTitle}&therapeutic_focus=${therapeuticFocus}`).then(res => {
            setEmailers(res.data[1]);
        })
    }, []);

    return (
        <div className="m-3 p-1">
            <h5 className="mb-3">{subCatTitle}</h5>
            {
                Emailers.map((e, key) => {
                    return (
                        <EmailerCard key={key} title={e.title} journalId={e.journal_id} />
                    )
                })
            }
        </div>
    )
}
