import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router";
import axios from 'axios';
import config from '../../config';

import TherapiesCard from '../../components/TherapiesCard/TherapiesCard';




const Therapies = () => {

    const navigate = useNavigate();
    const [SearchProduct, setSearchProducts] = useState("");
    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token !== config.TOKEN) {
            navigate("/");
        }

        // enable the header & bottom navigation bar
        document.getElementById("hideHeader").style.display = "block";
        document.getElementById("hideBottomNav").style.display = "block";
    }, []);

    const [Data, setData] = useState([]);

    useEffect(() => {
		
        let formData = new FormData();
        formData.append("key", localStorage.getItem("key"));

        axios.post(`${config.BASE_API_URI}/GetTherapy.php`, formData).then(res => {
			
            setData(res.data[1]);
			
        })
		
		
		
    }, []);
	
	const handleProductSearch = (e) => {
        e.preventDefault();

        setSearchProducts(e.target.value);
        if (e.keyCode === 13) {
            document.getElementById("productSearchBtn").click();
        }
      
    }
	
	 const doProductSearch = (e) => {
        e.preventDefault();
         navigate(`/searchproducts/${SearchProduct}`);
    }
	
    return (
        <>
			<div className="d-flex justify-content-center align-items-center p-1 mb-3 mx-auto">
                {<img src={`${config.CUSTOM_IMAGES_URL}/samples.png`} width="160px" />}
                {<b className='col-7'>Most easy and quick samples ordering system</b>}
            </div>
			<div className="p-4 mb-1 mx-auto" style={{maxWidth:"500px"}}> <center><input type="search" onKeyUp={handleProductSearch} placeholder="Search molecule" className="form-control border" id="ProductSearchBar" />
			
			</center>
				<button id="productSearchBtn" style={{ display: "none" }} onClick={doProductSearch}>Search molecule</button>
                 </div>
            <div className="row d-flex justify-content-center my-2 mx-auto">
				
                {
                    Data.map(d => {
                        return (
                            <TherapiesCard key={d.key} therapyId={d.therepy_id} title={d.title} />
                        )
                    })
                }
				
            </div>	

           
			
        </>
    );
}

export default Therapies;
