import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import config from '../../config';
import "./Contact.css"

import { Field, Formik, Form } from 'formik';
import axios from 'axios';

export default function Contact() {

    const navigate = useNavigate();

    const [ManagersData, setManagersData] = useState([]);
    const [loading,setLoading] = useState(false);
    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token !== config.TOKEN) {
            navigate("/");
        }

        let formData = new FormData();
        formData.append("key", localStorage.getItem("key"));

        axios.post(`${config.BASE_API_URI}/ContactUs.php`, formData).then(res => {
            setManagersData(res.data[1].result);
        }).catch(err => alert(err));
    }, []);

    return (
        <div>
            <Formik
                initialValues={{
                    message: ""
                }}
                onSubmit={data => {
                    let formData = new FormData();
                    formData.append("key", localStorage.getItem("key"));
                    formData.append("message", data.message);
                    setLoading(true);
                    axios.post(`${config.BASE_API_URI}/PriorityMessage.php`, formData).then(res => {
                        alert(res.data[1].Message);
                    }).finally(() => {
                        setLoading(false);
                    })
                }}>
                <Form className="m-md-5 mt-3 px-3">
                    <h5 className="m-2">Contact Us</h5>
                    <Field type="text" component="textarea" name="message" className="form-control border m-2 mx-auto" rows="8" placeholder="Write Your Message..!" />
                    <Field type="submit" value={loading ? "Sending..." :"Send Message"} className="btn btn-primary float-right m-2 roundBtn"  />
                </Form>
            </Formik>

            <div className="row mt-3 m-lg-3 contactDetails">

                <h5 className='col-12'>You can also contact our business manager:</h5>

                <div className='col-lg-6'>
                    <h5>Teritory Business Manager</h5>
                    <h6>{`${ManagersData.tbm_name}, ${ManagersData.tbm_mail}, ${ManagersData.tbm_country}-${ManagersData.tbm_phone}`}</h6>
                </div>

                <div className='col-lg-6'>
                    <h5>First Line Manager</h5>
                    <h6>{`${ManagersData.abm_name}, ${ManagersData.abm_mail}, ${ManagersData.abm_country}-${ManagersData.abm_phone}`}</h6>
                </div>

                <div className='col-lg-6'>
                    <h5>Second Line Manager</h5>
                    <h6>{`${ManagersData.rbm_name}, ${ManagersData.rbm_mail}, ${ManagersData.rbm_country}-${ManagersData.rbm_phone}`}</h6>
                </div>

                <div className='col-lg-6 mb-2'>
                    <h5>Third Line Manager</h5>
                    <h6>{`${ManagersData.sm_name}, ${ManagersData.rbm_mail}, ${ManagersData.rbm_country}-${ManagersData.rbm_phone}`}</h6>
                </div>
            </div>
        </div>
    )
}
