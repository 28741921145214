import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from "../../config";

export default function Privacy() {

    const [PrivacyData, setPrivacyData] = useState([]);

    useEffect(() => {
        axios.post(`${config.BASE_API_URI}/PrivacyPolicy.php`).then(res => {
            console.log(res.data[1][0]);
            setPrivacyData(res.data[1][0]);
        }).catch(err => alert(err));
    }, []);

    return (
        <div className="container">
            <h5 className="pb-2">Privacy Policy</h5>
            <div className="row d-flex align-items-start">
                <img src={`${config.PRIVACY_POLICY_BASE_URI}/${PrivacyData.page_id}.jpg`} alt="img" className="mb-3 col-lg-3" />
                <p className="col-lg-9" dangerouslySetInnerHTML={{ __html: PrivacyData.description }}></p>
            </div>
        </div>
    )
}
