import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router"
import { useParams } from 'react-router';
import PortalCard from '../../components/PortalCard/PortalCard'
import axios from 'axios';
import config from '../../config';

export default function Portals() {

    const navigate = useNavigate();

    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token !== config.TOKEN) {
            navigate("/");
        }
    }, []);

    const { linkName, therapeuticFocus, subCatTitle } = useParams();
    const [PortalData, setPortalData] = useState([]);

    useEffect(() => {
        axios.get(`${config.BASE_API_URI}/GetPortals.php?key=${localStorage.getItem("key")}&page=${linkName}&therapeutic_focus=${therapeuticFocus}`).then(res => {
            setPortalData(res.data[1]);
        })
    }, []);

    return (

        <div className="m-3 p-1">
            <h5 className="mb-4">{subCatTitle}</h5>

            {
                PortalData.map(p => {
                    return (
                        <PortalCard key={p.portal_id} portalInfo={p} />
                    )
                })
            }


        </div>

    )
}
