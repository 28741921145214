import React from 'react'
import { Link } from 'react-router-dom'

import { BiBookOpen } from "react-icons/bi"

export default function TherapyArticle({ therapyArticle }) {
    return (
        <div className="p-3 m-2 mt-3 row">
            <div className="title col-12">
                <h4>{therapyArticle.Atitle}</h4>
            </div>

            <div className="desc py-2 col-12 text-justify" dangerouslySetInnerHTML={{ __html: therapyArticle.description }}></div>
            {/* <div className="desc py-2 col-12 text-justify">
                {therapyArticle.description}
            </div> */}
            <div className="readmore pt-1 col-12">
                <Link to="/articles" className="font-weight-bold mr-2">Read All Articles</Link>

                <span>|</span>

                <Link to={`/journals/${therapyArticle.journal_id}`} className="font-weight-bold mr-2"> Read this Journal</Link>

                <a href={therapyArticle.link} target="_blank" rel="noopener noreferrer">
                    <BiBookOpen style={{ fontSize: "30px" }} className="float-right" />
                </a>
            </div>
            <hr className="col-12" />
        </div>
    )
}
