import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import background from "../../assets/background.png";
import initiative from "../../assets/initiative.png";
import LoginHeader from './LoginHeader';

const Login = () => {

    useEffect(() => {
        //hide header menu & bottom navigation bar on mobile
        document.getElementById("hideHeader").style.display = "none";
        document.getElementById("hideBottomNav").style.display = "none";
    });

    const navigate = useNavigate();

    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token === config.TOKEN) {
            navigate("/home");
        }
    }, []);

    const [CountryCode, setCountryCode] = useState("91")
    const [Contact, setContact] = useState("")

    const handleLogin = (e) => {
        e.preventDefault();

        let formData = new FormData();
        formData.append("country_code", CountryCode);
        formData.append("mobile_no", Contact);

        console.log(CountryCode, Contact);

        axios.post(`${config.BASE_API_URI}/check.php`, formData).then(res => {
            if (res.data[1].message) {
                alert(res.data[1].message);
            } else {
                sessionStorage.setItem("otp", res.data[1].otp);
                localStorage.setItem("key", res.data[1].key);
                navigate("/verify");
            }
        }).catch(err => {
            alert(err);
        })
    }

    return (
        <>
       <LoginHeader/>
            <div className="p-3 d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${background})`, height: "88vh", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                <form className="row" onSubmit={handleLogin}>
                    <div className='col-12'>
                        <img src={initiative} width="50%" alt="initiative img" />
                    </div>
                    <div className="dropdown col-lg-6 mt-2">
                        
                        <select className="form-control" onChange={(e) => setCountryCode(e.target.value)} required>
                            {/* <option value="" selected defaultValue disabled>-----Select Country Code------</option> */}
                            <option value="91">India</option>
                            <option value="977">Nepal</option>
                        </select>
                    </div>

                    <div className="form-group col-lg-6 mt-2">
                        
                        <input type="number" className="form-control" placeholder="Mobile Number" required
                            onChange={(e) => setContact(e.target.value)} />
                    </div>
                    <div className="form-check ml-3 col-12">
                        <input type="checkbox" className="form-check-input my-2" id="exampleCheck1" checked required />
                        <label className="form-check-label" htmlFor="exampleCheck1">Accept Terms</label>
                    </div>

                    <div className="ml-3 col-12 text-center">
                        <input type="submit" value="Get OTP" className="btn btn-primary roundBtn m-3 p-2" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default Login;
