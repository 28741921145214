const WEBSITE_LINK = "https://www.rpglifesciences.com";
//const WEBSITE_LINK = "http://localhost/projects/pharma/rpglifesciences.com";
const config = {
  WEBSITE_LINK: WEBSITE_LINK,
  TOKEN: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c",
  BASE_API_URI: `${WEBSITE_LINK}/admin/api/app`,
  CAT_IMG_BASE_URI: `${WEBSITE_LINK}/admin/rpgls/server/serv_categories`,
  PRODUCT_IMG_BASE_URI: `${WEBSITE_LINK}/admin/rpgls/server/products`,
  THERAPY_IMG_BASE_URI: `${WEBSITE_LINK}/admin/rpgls/server/therapeutic_focus`,
  SUBCAT_IMG_BASE_URI: `${WEBSITE_LINK}/admin/rpgls/server/serv_sub_categories`,
  VIDEOS_IMG_BASE_URI: `${WEBSITE_LINK}/admin/rpgls/server/videos`,
  PROFILE_PIC_BASE_URI: `${WEBSITE_LINK}/admin/pages/server/members`,
  PRIVACY_POLICY_BASE_URI: `${WEBSITE_LINK}/admin/pages/server/pages`,
  CUSTOM_IMAGES_URL: `${WEBSITE_LINK}/admin/vendor/server/documents/custom_images/files`,
  BANNERS_IMG_BASE_URI: `${WEBSITE_LINK}/admin/rpgls/server/banners`,
};

export default config;
