import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router';
import { useParams } from 'react-router';

import FileCard from '../../components/FileCard/FileCard';
import config from '../../config';

export default function Files() {

    const navigate = useNavigate();

    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token !== config.TOKEN) {
            navigate("/");
        }
    }, []);

    const { linkName, subCatTitle } = useParams();
    let formattedLinkName = linkName;
    if (linkName.indexOf(".") != -1) {
        formattedLinkName = linkName.substring(0, linkName.indexOf('.'));
    }

    const [Files, setFiles] = useState([]);

    useEffect(() => {
        const formData = new FormData();
        formData.append("key", localStorage.getItem("key"));
        formData.append("name", formattedLinkName);

        axios.post(`${config.BASE_API_URI}/files.php`, formData).then(res => {
            setFiles(res.data[1]);
        })
    }, []);

    return (
        <div className="m-3 p-1">
    		<h5 className="mb-3">{subCatTitle}</h5>
            <div className="row">
                {
                    Files.map(f => {
                        return (
                            <FileCard fileName={f.name} folderName={formattedLinkName} />
                        )
                    })
                }
            </div>
        </div>
    )
}
