import React from 'react';
import { Link } from "react-router-dom";
import config from '../../config';
import "./TherapiesCard.css";

export default function TherapiesCard({ key, therapyId, title }) {

		return (
	
        <Link to={`/products/${title}/`}  id="subCatCardLink" 
		className={"col-md-2 m-md-1 col-5 mx-auto subCatCard card-body d-flex flex-column align-items-center justify-content-around"}>
			<p className="text-center" style={{ fontSize: "0.6rem", margin: "-1rem auto -0.1rem auto" }}>{title}</p>
			<img src={`${config.THERAPY_IMG_BASE_URI}/${therapyId}`+".png"} alt="therapy" height="50%"/>
        </Link >
		)
}
