import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { useParams } from 'react-router';
import TherapyArticle from '../../components/TherapyArticle/TherapyArticle';
import config from '../../config';
import InfiniteScroll from 'react-infinite-scroll-component';
export default function TherapyArticles() {

    const navigate = useNavigate();

    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token !== config.TOKEN) {
            navigate("/");
        }
    }, []);

    const { therapy } = useParams();
	const PAGE = 1;							
    const [TherapyArticles, setTherapyArticles] = useState([]);
	const [PageNumber, setPageNumber] = useState(PAGE);												

    useEffect(() => {
        let formData = new FormData();
        formData.append("key", localStorage.getItem("key"));
        formData.append("Therapy", therapy);
		 formData.append("page", PageNumber);

        axios.post(`${config.BASE_API_URI}/GetTherapyArticles.php`, formData).then(res => {
            setTherapyArticles([...TherapyArticles , ...res.data[1].result]);
        }).catch(err => alert(err));
    }, [PageNumber]);
	const fetchMoreData = () => {
        setPageNumber(prev => prev + 1);
    }									
    return (
        <>				  
			<InfiniteScroll
                dataLength={TherapyArticles.length}
                next={fetchMoreData}
                hasMore={true}
                loader={<h4 style={{marginLeft:"40px"}}>Loading...</h4>}
            >				   
            {
                TherapyArticles.map((t, index) => {
                    return (
                        <TherapyArticle key={index} therapyArticle={t} />
                    )
                })
            }
			</InfiniteScroll >
        </>
    )
}
