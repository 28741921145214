import React, { useState } from 'react'
import axios from 'axios';
import config from '../../config';
import { convertToJson } from '../../helpers/utils';


const ServTherapySpecific = ({ subCatDetails }) => {
    const [loading,setLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        let formData = new FormData();
        formData.append("key", localStorage.getItem("key"));
        formData.append("message", subCatDetails.message);
        formData.append("category", subCatDetails.serv_category_id);
        formData.append("sub_category", subCatDetails.title);

        axios.post(`${config.BASE_API_URI}/ServTherapySpecific.php`, formData).then(res => {
			
			if(res.data[1].Message == 'Demo Account!'){
				alert(res.data[1].Message)
			}else{
			
            let resObj =  convertToJson(res.data)
            res.data[1].Message ? alert(res.data[1].Message) : alert(resObj.msg);
			}
			
        }).catch(err => alert(err)).finally(() => {
            setLoading(false);
            document.getElementById("closePopup").click();
        });
    }

    return (
        <form>
            <div>
                {subCatDetails.description !== "" && <div dangerouslySetInnerHTML={{ __html: subCatDetails.description }}></div>}
            </div>
            <div>
                {subCatDetails.demo_video !== "" &&
                    <div className="mt-3 embed-responsive embed-responsive-16by9">
                        <iframe className="embed-responsive-item"
                            src={subCatDetails.demo_video}>
                        </iframe>
                    </div>
                }
            </div>
            <div className="modal-footer">
                <button type="submit"  disabled ={loading} onClick={handleSubmit} className="btn btn-primary roundBtn">{loading ? "Processing...":"Order Now"}</button>
            </div>
        </form>
    )
}

export default ServTherapySpecific;