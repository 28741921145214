import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import "./BottomNav.css";
import { VscSignOut } from "react-icons/vsc";
import { MdOutlineContactSupport, MdOutlinePrivacyTip } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import $ from 'jquery';

import logo from "../../assets/logo.png";

import menu from "../../assets/icons/menu.png";
import home from "../../assets/icons/home.png";
import news from "../../assets/icons/news.png";
import user from "../../assets/icons/user.png";
import support from "../../assets/icons/handshake.png";
import config from '../../config';



export default function BottomNav() {

    const navigate = useNavigate();

    const [SearchStringMob, setSearchStringMob] = useState("");

    const handleSearch = (e) => {
        e.preventDefault();

        // setSearchStringMob(e.target.value);

        if (e.keyCode === 13) {
            document.getElementById("searchBtn").click();
        }
    }

    const doSearch = (e) => {
        e.preventDefault();
        navigate(`/searcharticles/${SearchStringMob}`);
    }

    const openSlideMenu = () => {
        document.getElementById('menu').style.width = '250px';
        document.getElementById('content').style.marginLeft = '250px';
    }

    const closeSlideMenu = () => {
        document.getElementById('menu').style.width = '0';
        document.getElementById('content').style.marginLeft = '0';
    }

    return (
        <div className="d-md-none" id="hideBottomNav">
            <div id="content">
                <div id="menu" className="nav1">
                    <a href="#" className="close" id="sidebarCloseBtn" onClick={closeSlideMenu}>
                        <i className="fas fa-times"></i>
                    </a>

                    <div className="d-flex flex-column">
                        <img src={logo} className="mx-auto mb-3" width="70%" alt="logo" />
						{<br />}
						   <a href={config.WEBSITE_LINK}  target="_blank" className=' link__btn btn btn-primary text-white' style={{color:'#fff !important' , maxWidth:"91.666667%", margin: "0 auto 10px auto", padding: "5px 28px", borderRadius:"50px", backgroundColor:"#103178"}}>

                            Company Portal
                        </a>
						{<br />}
                        
                    </div>
                    {/* <br /> */}
                    {/* <hr /> */}
                    <Link to="/" className='d-flex align-items-center border-bottom border-secondary'>
                        <img src={home} width="25px" height="25px" alt="icon" />
                        <span className='pl-1'>Home</span>
                    </Link>
                    {/* <hr /> */}

                    <Link to="/articles" className='d-flex align-items-center border-bottom border-secondary'>
                        <img src={news} width="25px" height="25px" alt="icon" />
                        <span className='pl-1'>Articles</span>
                    </Link>
                    {/* <hr /> */}
                    <Link to="/profile" className='d-flex align-items-center border-bottom border-secondary'>
                        <img src={user} width="25px" height="25px" alt="icon" />
                        <span className='pl-1'>My Profile</span>
                    </Link>
                    {/* <hr /> */}
                    <Link to="/services" className='d-flex align-items-center border-bottom border-secondary'>
                        <img src={support} width="25px" height="25px" alt="icon" />
                        <span className='pl-1'>My Services</span>
                    </Link>
                    {/* <hr /> */}
                    <Link to="/contact" className='d-flex align-items-center border-bottom border-secondary'>
                        {/* <img src={home} width="25px" height="25px" alt="icon" /> */}
                        <MdOutlineContactSupport style={{ fontSize: "25px" }} />
                        <span className='pl-1'>Contact</span>
                    </Link>
                    <Link to="/privacy" className='d-flex align-items-center border-bottom border-secondary'>
                        <MdOutlinePrivacyTip style={{ fontSize: "25px" }} />
                        <span className='pl-1'>Privacy Policy</span>
                    </Link>
                    <Link to="/settings" className='d-flex align-items-center border-bottom border-secondary'>
                        <IoSettingsOutline style={{ fontSize: "25px" }} />
                        <span className='pl-1'>Settings</span>
                    </Link>
                    {/* <hr /> */}
                    <Link onClick={() => {
                        localStorage.clear();
                        sessionStorage.clear();
                    }}
                        to="/" className='d-flex align-items-center'>
                        {/* <img src={home} width="25px" height="25px" alt="icon" /> */}
                        <VscSignOut style={{ fontSize: "25px" }} />
                        <span className='pl-1'>Logout</span>
                    </Link>
                    {/* <hr /> */}
                </div>
            </div>

            <nav className="nav">

                <span className="nav__link" id="openMenu" onClick={openSlideMenu}>
                    {/* <i className="material-icons nav__icon">menu_open</i> */}
                    <img src={menu} id="openMenu" width="30px" alt="icon" />
                    {/* <span className="nav__text">Menu</span> */}
                </span>

                <Link to="/home" className="nav__link">
                    {/* <i className="material-icons nav__icon">home</i> */}
                    <img src={home} width="30px" alt="icon" />
                    {/* <span className="nav__text">Home</span> */}
                </Link>

                <Link to="/articles" className="nav__link">
                    {/* <i className="material-icons nav__icon">article</i> */}
                    <img src={news} width="30px" alt="icon" />
                    {/* <span className="nav__text">Articles</span> */}
                </Link>

                <Link to="/profile" className="nav__link">
                    {/* <i className="material-icons nav__icon">person</i> */}
                    <img src={user} width="30px" alt="icon" />
                    {/* <span className="nav__text">Profile</span> */}
                </Link>

                <Link to="/services" className="nav__link">
                    {/* <i className="material-icons nav__icon">medical_services</i> */}
                    <img src={support} width="30px" alt="icon" />
                    {/* <span className="nav__text">Services</span> */}
                </Link>
            </nav>
        </div>
    )
}
