import React, { useState } from 'react'
import axios from 'axios';
import config from '../../config';
import { convertToJson } from '../../helpers/utils';

const ServWebinars = ({ subCatDetails }) => {

    const [WebinarTitle, setWebinarTitle] = useState("");
    const [WebinarDate, setWebinarDate] = useState("");
    const [WebinarTime, setWebinarTime] = useState("");
    const [WebinarInfo, setWebinarInfo] = useState("");
    const [loading,setLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        let formData = new FormData();
        formData.append("key", localStorage.getItem("key"));
        formData.append("message", subCatDetails.message);
        formData.append("category", subCatDetails.serv_category_id);
        formData.append("sub_category", subCatDetails.title);
        formData.append("webinar_date_on", WebinarDate);
        formData.append("webinar_time", WebinarTime);
        formData.append("webinar_title", WebinarTitle);
        formData.append("webinar_addl_info", WebinarInfo);

        axios.post(`${config.BASE_API_URI}/ServWebinar.php`, formData).then(res => {
            
			if(res.data[1].Message == 'Demo Account!'){
				alert(res.data[1].Message)
			}else{
			
            let resObj =  convertToJson(res.data)
            res.data[1].Message ? alert(res.data[1].Message) : alert(resObj.msg);
			}
			
        }).catch(err => alert(err)).finally(() => {
            setLoading(false);
            document.getElementById("closePopup").click();
        });
    }

    return (
        <div className="overflow-auto" style={{ maxHeight: "85vh" }}>
            <div>
                {subCatDetails.description !== "" && <div dangerouslySetInnerHTML={{ __html: subCatDetails.description }}></div>}
            </div>
            <div>
                {subCatDetails.demo_video !== "" &&
                    <div className="my-3 embed-responsive embed-responsive-16by9">
                        <iframe className="embed-responsive-item"
                            src={subCatDetails.demo_video}>
                        </iframe>
                    </div>
                }
            </div>
            <form action="" className="my-3">
                {/* <label htmlFor="date">Select : </label> */}
                <input type="text" onChange={(e) => setWebinarTitle(e.target.value)} placeholder="Title" className="form-control" /> <br />
                <label htmlFor="date">Select Date: </label>
                <input type="date" onChange={(e) => setWebinarDate(e.target.value)} className="form-control" /> <br />
                <label htmlFor="time">Select Time: </label>
                <input type="time" onChange={(e) => setWebinarTime(e.target.value)} className="form-control" /> <br />
                <textarea onChange={(e) => setWebinarInfo(e.target.value)} placeholder="Additional Information" className="form-control"></textarea> <br />
            </form>
            <div className="modal-footer">
            <button type="submit" disabled ={loading} onClick={handleSubmit} className="btn btn-primary roundBtn">{loading ? "Processing...":"Order Now"}</button>
            </div>
        </div>
    )
}

export default React.memo(ServWebinars);