import React, { useState } from 'react'
import axios from 'axios';
import config from '../../config';
import { convertToJson } from '../../helpers/utils';

const Sanitization = ({ subCatDetails }) => {

    const [SanitizationDate, setSanitizationDate] = useState("");
    const [SanitizationTime, setSanitizationTime] = useState("");
    const [loading,setLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
      
        let formData = new FormData();
        formData.append("key", localStorage.getItem("key"));
        formData.append("message", subCatDetails.message);
        formData.append("category", subCatDetails.serv_category_id);
        formData.append("sub_category", subCatDetails.title);
        formData.append("date_on", SanitizationDate);
        formData.append("time_at", SanitizationTime);

        axios.post(`${config.BASE_API_URI}/ServSanitization.php`, formData).then(res => {
			
			if(res.data[1].Message == 'Demo Account!'){
				alert(res.data[1].Message)
			}else{
			
            let resObj =  convertToJson(res.data)
            res.data[1].Message ? alert(res.data[1].Message) : alert(resObj.msg);
			}
			
            
        }).catch(err => {
            alert(err);

        }).finally(() => {
            setLoading(false);
            document.getElementById("closePopup").click();
        });
    }
    return (
        <>
            <div className="overflow-auto" style={{ maxHeight: "85vh" }}>

                <div>
                    {subCatDetails.description !== "" && <div dangerouslySetInnerHTML={{ __html: subCatDetails.description }}></div>}
                </div>
                <div>
                    {subCatDetails.demo_video !== "" &&
                        <div className="my-3 embed-responsive embed-responsive-16by9">
                            <iframe className="embed-responsive-item"
                                src={subCatDetails.demo_video}>
                            </iframe>
                        </div>
                    }
                </div>
                <form action="" className="my-3">
                    <label htmlFor="date">Select Date: </label>
                    <input type="date" name="date" onChange={(e) => setSanitizationDate(e.target.value)} className="form-control" /> <br />
                    <label htmlFor="time">Select Time: </label>
                    <input type="time" name="time" onChange={(e) => setSanitizationTime(e.target.value)} className="form-control" /> <br />
                </form>
                <div className="modal-footer">
                    <button type="button"  disabled ={loading} onClick={handleSubmit} className="btn btn-primary roundBtn" style={{ backgroundColor: "#103178" }}>{loading ? "Processing...":"Order Now"}</button>
                </div>
            </div>
        </>
    )
}

export default React.memo(Sanitization);