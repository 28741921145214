import React, { useState } from 'react'
import axios from 'axios';
import config from '../../config';
import { convertToJson } from '../../helpers/utils';
import { Link } from "react-router-dom";


const ServOnline = ({ subCatDetails }) => {
    const [loading,setLoading] = useState(false);

    const handleSubmit = (e) => {
		
		e.preventDefault();
		  const userConfirmed = window.confirm("Want to order now?");
			if (userConfirmed) {
			  
        e.preventDefault();
        setLoading(true);

        let formData = new FormData();
        formData.append("key", localStorage.getItem("key"));
        formData.append("message", subCatDetails.message);
        formData.append("category", subCatDetails.serv_category_id);
        formData.append("sub_category", subCatDetails.title);

        axios.post(`${config.BASE_API_URI}/ServDigital.php`, formData).then(res => {
			
			if(res.data.Message == 'Demo Account!'){
				alert(res.data.Message)
			}else{
				
			 if(res.data.status == 'success'){
				 alert(res.data.msg)
				window.open(res.data.link)
			}
			
			 if(res.data.status == 'error'){
				 alert(res.data.msg)
			}
			
            
           
			}
			
        }).catch(err => alert(err)).finally(() => {
            setLoading(false);
            document.getElementById("closePopup").click();
        });
			}
    }

    return (
        <form>
            <div className="d-flex justify-content-between">
                {subCatDetails.description !== "" && <div dangerouslySetInnerHTML={{ __html: subCatDetails.description }}></div>}
				<a className="btn btn-sm btn-info rounded" href={subCatDetails.sample_link}>Demo</a>
            </div>
			
            <div>
                {subCatDetails.demo_video !== "" &&
                    <div className="mt-3 embed-responsive embed-responsive-16by9">
                        <iframe className="embed-responsive-item"
                            src={subCatDetails.demo_video}>
                        </iframe>
                    </div>
                }
            </div>
            <div className="modal-footer">
                <button type="submit"  disabled ={loading} onClick={handleSubmit} className="btn btn-primary roundBtn">{loading ? "Processing...":"Order Now"}</button>
            </div>
        </form>
    )
}

export default ServOnline;