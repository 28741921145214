import React from 'react'
import { Link } from 'react-router-dom'
import config from '../../config';
import download from "../../assets/icons/download.png";

export default function FileCard({ fileName, folderName }) {
	
	let key = localStorage.getItem("key");
	
    return (
	
        <div className="col-md-4 d-flex just-content-center align-items-center">
            <span className="mt-1 col-10">
                <Link to="" className="ml-2">- {fileName}</Link>
                <hr />
            </span>
			
            <a href={`${config.WEBSITE_LINK}/website/download_document.php?a=${fileName}&folder=${folderName}&key=${key}`}  className="btn col-1" download><img src={download} alt="icon" width="30px" height="30px" className="emailerIcons" /></a>
        </div >
			
    )
}
