import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import "./Profile.css"
import { BsPencil } from "react-icons/bs"
import axios from 'axios';
import config from '../../config';
import profile from "../../assets/icons/profile.png"

export default function Profile() {

    const navigate = useNavigate();

    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token !== config.TOKEN) {
            navigate("/");
        }
    }, []);
	
	
    const [ProfileData, setProfileData] = useState([]);
	const [Data, setData] = useState([]);
	const [selectedValue, setSelectedValue] = useState('');
    useEffect(() => {
        let profileData = JSON.parse(localStorage.getItem("profileData"));
        setProfileData(profileData);
		
		  let formData = new FormData();
        formData.append("key", localStorage.getItem("key"));
			
        axios.post(`${config.BASE_API_URI}/GetAllTherapy.php`, formData).then(res => {	
        setData(res.data[1]);
			console.log(Data)	
        })
    }, []);
	
	const handleChange = (event) => {
		    const value = event.target.value;
			
			
			const updatedProfileData = {
				  ...ProfileData,
				  therapeutic_focus: value // Replace 'new focus' with the desired new value
				};
				
				// Update state and localStorage
				setProfileData(updatedProfileData);
				localStorage.setItem('profileData', JSON.stringify(updatedProfileData))
				setSelectedValue(value)
			
				//Call SetTherapy.php
				let formData = new FormData();
				formData.append("key", localStorage.getItem("key"));
				formData.append("therapeutic_focus",value);

				axios.post(`${config.BASE_API_URI}/SetTherapy.php`, formData).then(res => {
					
					alert(res.data[1].Message);
				})
				//Call SetTherapy.php
				
				navigate("/home");
	 }

    const { salutation, phone, first_name, last_name, email, birth_date, qualification,
        g_plus_link, facebook_link, twitter_link, linkedIn_link, link_to_blog, department,
        location, practice_hours, payment_mode_number, payment_mode_one, payment_mode_two,
        address, personal_intro, member_id, IDphoto } = ProfileData;
    return (
        <div className="row m-1 p-2 d-flex justify-content-around profileBox">
		
            {
			<div className="col-11 d-flex justify-content-between m-2">
                <img src={IDphoto == "Y" ? `${config.PROFILE_PIC_BASE_URI}/${member_id}.jpg` : profile} width="50px" alt="prfile" />
					{/*<Link to="/edit-profile" className="btn my-auto">
                    <BsPencil />
					</Link>*/}
			
			{(ProfileData.member_role != '24')?
				<div>
					 <select className="form-control" value={ProfileData.therapeutic_focus} onChange={handleChange}>
					  {Data.map(val => (
						<option key={val.therepy_id} value={val.title} selected>
						  {val.title}
						</option>
					  ))}
					</select>
					
			</div>:''	
			}
				
            </div>
			}
		
            <fieldset className="col-md-11">

                <span>{personal_intro}</span>
            </fieldset>

            <fieldset className="col-md-5 border p-2">
                <legend className="w-auto">Personal Info</legend>
                <div>
                    <b>Name : </b>
                    <span>{salutation + " " + first_name + " " + last_name}</span>
                </div>
                <div>
                    <b>Mobile Number : </b>
                    <span>{phone}</span>
                </div>
                <div>
                    <b>Email : </b>
                    <span>{email}</span>
                </div>
                <div>
                    <b>Birth Date : </b>
                    <span>{birth_date}</span>
                </div>
                <div>
                    <b>Qualification : </b>
                    <span>{qualification}</span>
                </div>
            </fieldset>

            <fieldset className="col-md-5 border p-2">
                <legend className="w-auto">Social</legend>
                <div>
                    <b>G+Plus Link : </b>
                    <span>{g_plus_link}</span>
                </div>
                <div>
                    <b>Facebook Link : </b>
                    <span>{facebook_link}</span>
                </div>
                <div>
                    <b>Twitter Link : </b>
                    <span>{twitter_link}</span>
                </div>
                <div>
                    <b>Linkedin Link : </b>
                    <span>{linkedIn_link}</span>
                </div>
                <div>
                    <b>Link To Blog : </b>
                    <span>{link_to_blog}</span>
                </div>
            </fieldset>

            <fieldset className="col-md-5 border p-2">
                <legend className="w-auto">Work</legend>
                <div>
                    <b>Department : </b>
                    <span>{department}</span>
                </div>
                <div>
                    <b>Location : </b>
                    <span>{location}</span>
                </div>
                <div>
                    <b>Practice Hours : </b>
                    <span>{practice_hours}</span>
                </div>
            </fieldset>

            <fieldset className="col-md-5 border p-2">
                <legend className="w-auto">Payment</legend>
                <div>
                    <b>Payment Number : </b>
                    <span>{payment_mode_number}</span>
                </div>
                <div>
                    <b>Payment Mode One : </b>
                    <span>{payment_mode_one}</span>
                </div>
                <div>
                    <b>Payment Mode Two : </b>
                    <span>{payment_mode_two}</span>
                </div>
            </fieldset>

            <fieldset className="col-md-5 border p-2">
                <legend className="w-auto">Address</legend>
                <div>
                    <b>Address : </b>
                    <span>{address}</span>
                </div>
            </fieldset>
        </div>
    )
}
