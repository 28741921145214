import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { useParams } from 'react-router'

import config from '../../config';

export default function Viewpdf() {

    const navigate = useNavigate();

    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token !== config.TOKEN) {
            navigate("/");
        }
    }, []);

    const { subCatTitle, therapeuticFocus, file_name } = useParams();
    const [Emailers, setEmailers] = useState([]);

    useEffect(() => {
        const key = localStorage.getItem("key");
        axios.post(`${config.BASE_API_URI}/GetEmailers.php?key=${key}&subcategory_title=${subCatTitle}&therapeutic_focus=${therapeuticFocus}`).then(res => {
            setEmailers(res.data[1]);
        })
    }, []);

    return (
	<>
        
		<div className="mx-2">
		 <iframe src={`${config.WEBSITE_LINK}/website/download_document.php?`+file_name} width="100%" height="700px"> </iframe>
		</div>
		</>
    )
}
