import React, { useState } from 'react'
import axios from 'axios';
import config from '../../config';
// import $ from 'jquery';
import { convertToJson } from '../../helpers/utils';


const ServPrints = ({ subCatDetails }) => {


     const [selectedFile, setselectedFile] = useState('')
    const [loading,setLoading] = useState(false);
	const [progress, setProgress] = useState(0)

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        let formData = new FormData();
        formData.append("key", localStorage.getItem("key"));
        formData.append("message", subCatDetails.message);
        formData.append("category", subCatDetails.serv_category_id);
        formData.append("sub_category", subCatDetails.title);
        formData.append("print_pdf", selectedFile);

        axios.post(`${config.BASE_API_URI}/ServPrints.php`, formData,{
        onUploadProgress: (progressEvent) => {
          const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          setProgress(percentage)
          
          console.log('Upload Progress:', percentage + '%')
        },
      }).then(res => {
			
			if(res.data[1].Message == 'Demo Account!'){
				alert(res.data[1].Message)
			}else{
			
            let resObj =  convertToJson(res.data)
            res.data[1].Message ? alert(res.data[1].Message) : alert(resObj.msg);
			}
			
        }).catch(err => alert(err)).finally(() => {
            setLoading(false);
            document.getElementById("closePopup").click();
        });
    }

    return (
        <>
            <div className="overflow-auto" style={{ maxHeight: "85vh" }}>
                <div>
                    {subCatDetails.description !== "" && <div dangerouslySetInnerHTML={{ __html: subCatDetails.description }}></div>}
                </div>
                
                <div>
                    {subCatDetails.demo_video !== "" &&
                        <div className="my-3 embed-responsive embed-responsive-16by9">
                            <iframe className="embed-responsive-item"
                                src={subCatDetails.demo_video}>
                            </iframe>
                        </div>
                    }
                </div>
                <form action="" className="my-3">
                    <label className="mr-3" for="printFile">Upload Print PDF</label>
                    <input
					  type="file"
					  className="rounded-0 mr-3"
					  id="printFile"
					  onChange={(e) => setselectedFile(e.target.files[0])}
					/>
					<div className="progress">
					  <div className="progress-bar"  style={{ width: `${progress}%` }}>  {progress}% </div>
					</div>
                </form>
                <div className="modal-footer">
                <button type="submit" disabled ={loading} onClick={handleSubmit} className="btn btn-primary roundBtn">{loading ? "Processing...":"Order Prints"}</button>
                </div>
            </div>
        </>
    )
}

export default React.memo(ServPrints);