import React from 'react'
import { Link,Navi } from 'react-router-dom'

import { BiBookOpen } from "react-icons/bi"

export default function Article({ article}) {
    return (
        <div className="p-3 m-2 mt-3 row">
            <div className="title col-12">
                <h4>{article.Atitle}</h4>
            </div>
            <div className="col-12 text-dark" dangerouslySetInnerHTML={{ __html: article.reference }}></div>
            {/* <div className="col-12">
                <p className="text-dark">{article.reference}</p>
            </div> */}

            <div className="desc py-2 col-12 text-justify" dangerouslySetInnerHTML={{ __html: article.description }}></div>
            {/* <div className="desc py-2 col-12 text-justify">
                {article.description}
            </div> */}
            <div className="readmore pt-1 col-12">
                <Link to={`/journals/${article.journal_id}`} className="font-weight-bold mr-2">Read this Journal</Link>

                <span>|</span>

                <Link to={`/articles/therapy/${article.therapy}`} className="font-weight-bold mr-2"> Read More: {article.therapy}</Link>
                
              
            {/* <Link 
state={{ link: article.link}}             

to={`/articles/${article.Atitle}`}
                className="font-weight-bold mr-2"> 
            <BiBookOpen style={{ fontSize: "30px" }} className="float-right" />
            </Link> */}

                <a target="_blank" href={`${article.link}`}>
                    <BiBookOpen style={{ fontSize: "30px" }} className="float-right" />
                </a>
            </div>
            <hr className="col-12" />
        </div>
    )
}
