import React, { useState } from 'react'
import axios from 'axios';
import config from '../../config';
// import $ from 'jquery';
import { convertToJson } from '../../helpers/utils';


const ServPrintables = ({ subCatDetails }) => {


    const [Language, setLanguage] = useState("");
    const [loading,setLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        let formData = new FormData();
        formData.append("key", localStorage.getItem("key"));
        formData.append("message", subCatDetails.message);
        formData.append("category", subCatDetails.serv_category_id);
        formData.append("sub_category", subCatDetails.title);
        formData.append("printable_language", Language);

        axios.post(`${config.BASE_API_URI}/ServPrintables.php`, formData).then(res => {
			
			if(res.data[1].Message == 'Demo Account!'){
				alert(res.data[1].Message)
			}else{
			
            let resObj =  convertToJson(res.data)
            res.data[1].Message ? alert(res.data[1].Message) : alert(resObj.msg);
			}
			
        }).catch(err => alert(err)).finally(() => {
            setLoading(false);
            document.getElementById("closePopup").click();
        });
    }

    return (
        <>
            <div className="overflow-auto" style={{ maxHeight: "85vh" }}>
                <div>
                    {subCatDetails.description !== "" && <div dangerouslySetInnerHTML={{ __html: subCatDetails.description }}></div>}
                </div>
                <div className="mt-4">
                    <img src={`${config.SUBCAT_IMG_BASE_URI}/${subCatDetails.serv_sub_category_id}.jpg`} width="100%" height="100%" alt="" />
                </div>
                <div>
                    {subCatDetails.demo_video !== "" &&
                        <div className="my-3 embed-responsive embed-responsive-16by9">
                            <iframe className="embed-responsive-item"
                                src={subCatDetails.demo_video}>
                            </iframe>
                        </div>
                    }
                </div>
                <form action="" className="my-3">
                    <label for="exampleInputCountry">Select Language</label>
                    <select className="form-control" onChange={(e) => setLanguage(e.target.value)} required="true">
                        <option value="" selected disabled defaultValue>-----Select Language------</option>
                        <option value="English">English</option>
						<option value="Hindi">Hindi</option>
                    </select> <br />
                </form>
                <div className="modal-footer">
                <button type="submit" disabled ={loading} onClick={handleSubmit} className="btn btn-primary roundBtn">{loading ? "Processing...":"Order Now"}</button>
                </div>
            </div>
        </>
    )
}

export default React.memo(ServPrintables);