import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { Formik, Form, Field } from 'formik'
import { Link } from 'react-router-dom'
import "./EditProfile.css"
import { IoCaretBackSharp } from "react-icons/io5"
import axios from 'axios'
import config from '../../config'
import profile from "../../assets/icons/profile.png"

export default function EditProfile() {

    const navigate = useNavigate();

    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token !== config.TOKEN) {
            navigate("/");
        }
    }, []);

    const [CurrentProfileData, setCurrentProfileData] = useState([]);

    useEffect(() => {
        let profileData = JSON.parse(localStorage.getItem("profileData"));
        setCurrentProfileData(profileData);
    }, []);

    let {
        abm,
        address,
        birth_date,
        country_code,
        department,
        email,
        facebook_link,
        first_name,
        flaga,
        flagb,
        g_plus_link,
        headquarter,
        last_name,
        link_to_blog,
        linkedIn_link,
        location,
        middle_name,
        payment_mode_number,
        payment_mode_one,
        payment_mode_two,
        personal_intro,
        phone,
        position,
        practice_hours,
        premium,
        professional_level,
        qualification,
        rbm,
        salutation,
        sm,
        tbm,
        therapeutic_focus,
        twitter_link,
        member_id,
        IDphoto
    } = CurrentProfileData;

    const updateProfileImg = (e) => {
        e.preventDefault();

        let formData = new FormData();
        formData.append("key", localStorage.getItem("key"));
        formData.append("profile_image", e.target.files[0]);

        axios.post(`${config.BASE_API_URI}/UpdatePhoto.php`, formData).then(res => {
        }).catch(err => alert(err));
    }

    return (
        <Formik
            enableReinitialize
            initialValues={{
                key: localStorage.getItem("key"),
                abm,
                address,
                birth_date,
                country_code,
                department,
                email,
                facebook_link,
                first_name,
                flaga,
                flagb,
                g_plus_link,
                headquarter,
                last_name,
                link_to_blog,
                linkedIn_link,
                location,
                middle_name,
                payment_mode_number,
                payment_mode_one,
                payment_mode_two,
                personal_intro,
                phone,
                position,
                practice_hours,
                premium,
                professional_level,
                qualification,
                rbm,
                salutation,
                sm,
                tbm,
                therapeutic_focus,
                twitter_link,
            }}
            onSubmit={data => {
                let formData = new FormData();

                formData.append("key", data.key);
                formData.append("personal_intro", data.personal_intro);
                formData.append("first_name", data.first_name);
                formData.append("last_name", data.last_name);
                formData.append("birth_date", data.birth_date);
                formData.append("qualification", data.qualification);
                formData.append("location", data.location);
                formData.append("department", data.department);
                formData.append("practice_hours", data.practice_hours);
                formData.append("address", data.address);
                formData.append("g_plus_link", data.g_plus_link);
                formData.append("facebook_link", data.facebook_link);
                formData.append("twitter_link", data.twitter_link);
                formData.append("linkedIn_link", data.linkedIn_link);
                formData.append("link_to_blog", data.link_to_blog);
                formData.append("payment_mode_one", data.payment_mode_one);
                formData.append("payment_mode_two", data.payment_mode_two);
                formData.append("payment_mode_number", data.payment_mode_number);

                // formData.append("salutation", data.salutation);
                // formData.append("middle_name", data.middle_name);
                // formData.append("position", data.position);

                // formData.append("phone", data.phone);
                // formData.append("email", data.email);
                // formData.append("profile_image", data.profile_image);

                axios.post(`${config.BASE_API_URI}/UpdateProfile.php`, formData).then(res => {
                }).catch(err => alert(err));
            }}
        >

            <Form className="row m-1 p-2 d-flex justify-content-around profileBox">
                <div className="col-12 d-flex justify-content-between m-2">

                    <label htmlFor="image">
                        <input type="file" accept="image/*" onChange={updateProfileImg} name="image" id="image" style={{ display: 'none' }} />
                        <img src={IDphoto == "Y" ? `${config.PROFILE_PIC_BASE_URI}/${member_id}.jpg` : profile} width="50px" alt="profile" />
                    </label>

                    <Link to="/profile" className="btn my-auto">
                        <IoCaretBackSharp />
                    </Link>
                </div>

                <fieldset className="col-md-11 border p-2">
                    <legend className="w-auto">Personal Intro</legend>
                    <div>
                        <Field name="personal_intro" type="text" component="textarea" placeholder="Personal Intro" className="form-control" />
                    </div>
                </fieldset>

                <fieldset className="col-md-5 border p-2">
                    <legend className="w-auto">Personal Info</legend>
                    <div>
                        <Field type="text" name="first_name" className="form-control" placeholder="First Name" />
                    </div>
                    <div>
                        <Field type="text" name="last_name" className="form-control" placeholder="Last Name" />
                    </div>
                    <div>
                        <Field type="text" name="phone" className="form-control" placeholder="Contact Number" disabled="true" />
                    </div>
                    <div>
                        <Field type="text" name="email" placeholder="Email" className="form-control" disabled="true" />
                    </div>
                    <div>
                        <span><Field type="text" name="birth_date" placeholder="Birth Date" className="form-control" /></span>
                    </div>

                    <div>
                        <span><Field type="text" name="qualification" placeholder="Qualification" className="form-control" /></span>
                    </div>
                </fieldset>

                <fieldset className="col-md-5 border p-2">
                    <legend className="w-auto">Social</legend>
                    <div>
                        <Field type="text" name="g_plus_link" placeholder="G+Plus Link" className="form-control" />
                    </div>

                    <div>
                        <Field type="text" name="facebook_link" placeholder="Facebook Link" className="form-control" />
                    </div>

                    <div>
                        <Field type="text" name="twitter_link" placeholder="Twitter Link" className="form-control" />
                    </div>

                    <div>
                        <Field type="text" name="linkedIn_link" placeholder="Linkedin Link" className="form-control" />
                    </div>

                    <div>
                        <Field type="text" name="link_to_blog" placeholder="Link To Blog" className="form-control" />
                    </div>
                </fieldset>

                <fieldset className="col-md-5 border p-2">
                    <legend className="w-auto">Work</legend>
                    <div>
                        <Field type="text" name="department" placeholder="Department" className="form-control" />
                    </div>
                    <div>
                        <Field type="text" name="location" placeholder="Location" className="form-control" />
                    </div>
                    <div>
                        <Field type="text" name="practice_hours" placeholder="Practice Hours" className="form-control" />
                    </div>
                </fieldset>

                <fieldset className="col-md-5 border p-2">
                    <legend className="w-auto">Payment</legend>
                    <div>
                        <Field type="text" name="payment_mode_number" placeholder="Payment Mode Number" className="form-control" />
                    </div>
                    <div>
                        <Field type="text" name="payment_mode_one" placeholder="Payment Mode One" className="form-control" />
                    </div>
                    <div>
                        <Field type="text" name="payment_mode_two" placeholder="Payment Mode Two" className="form-control" />
                    </div>
                </fieldset>

                <fieldset className="col-md-5 border p-2">
                    <legend className="w-auto">Address</legend>
                    <div>
                        <Field type="text" name="address" placeholder="Address" className="form-control" />
                    </div>
                </fieldset>

                <div className="col-md-11 my-2">
                    <Field name="submit" type="submit" value="Update Details" className="float-right btn btn-primary roundBtn" style={{ backgroundColor: "var(--btn-color) !important" }} />
                </div>
            </Form>
        </Formik>
    )
}
