import React from 'react'
import { Link } from 'react-router-dom';
import "./EmailerCard.css";
import download from "../../assets/icons/download.png"
import planes from "../../assets/icons/planes.png"

import { BiBookOpen } from "react-icons/bi";
import axios from 'axios';
import config from '../../config';

export default function EmailerCard({ title, journalId }) {
	
	let key = localStorage.getItem("key");

    const handleSendEmailer = () => {
        const formData = new FormData();
        formData.append("key", localStorage.getItem("key"));
        formData.append("journalID", journalId);
        axios.post(`${config.BASE_API_URI}/SendEmailer.php`, formData).then(res => {
            alert(res.data);
        }).catch(err => alert(err));
    }

    return (
        <div className="d-flex justify-content-between">
            <span>
                <span className="ml-2 mb-2">- {title}</span>
                <hr />
            </span>
            <div className="d-flex justify-content-between">
                <a href={`/journals/${journalId}`}>
                    <BiBookOpen width="35px" height="35px" className="emailerIcons" />
                </a>
				
                <a href={`${config.WEBSITE_LINK}/website/download_document.php?dcipdf=${journalId}.pdf&folder=journals&key=${key}`} download>
                    <img src={download} alt="icon" width="30px" height="30px" className="emailerIcons" />
                </a>
				
                <img src={planes} alt="icon" width="25px" height="25px" className="emailerIcons" onClick={handleSendEmailer} />
            </div>
        </div>
    )
}
