import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import ServiceCard from '../../components/ServiceCard/ServiceCard'
import config from '../../config';

export default function Services() {

    const navigate = useNavigate();

    const [OrderHistory, setOrderHistory] = useState([]);

    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token !== config.TOKEN) {
            navigate("/");
        }

        //get order history
        let formData = new FormData();
        formData.append("key", localStorage.getItem("key"));

        axios.post(`${config.BASE_API_URI}/OrderHistory.php`, formData).then(res => {
            setOrderHistory(res.data[1].result);
        })
    }, []);

    return (
        <div className="m-3 p-1">
            <h5 className="mb-3">Order History</h5>

            {
                OrderHistory.map(order => {
                    return (
                        <ServiceCard key={order.ID} orderDetails={order} />
                    )
                })
            }

        </div>

    )
}
