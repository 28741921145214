import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { useParams } from 'react-router';
import Banner from '../../components/Banner/Banner'
import SubCategoriesCard from "../../components/SubCategoriesCard/SubCategoriesCard";
import config from '../../config';

export default function Subcategories() {

    const navigate = useNavigate();

    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token !== config.TOKEN) {
            navigate("/");
        }
    }, []);

    const [SubCategories, setSubCategories] = useState([]);
    const { catId, desc } = useParams();

    useEffect(() => {

        const profileData = JSON.parse(localStorage.getItem("profileData"));
		
		

        axios.get(`${config.BASE_API_URI}/GetSubCategory.php?key=${localStorage.getItem("key")}&category_id=${catId}&therapeutic_focus=${profileData.therapeutic_focus}`).then(res => {
            setSubCategories(res.data[1]);
			
			const member_role_value = res.data[0].member_role 

			const updatedProfileData = {
				  ...profileData,
				  member_role: member_role_value, // Replace 'new focus' with the desired new value
				};
				
				// Update state and localStorage
				localStorage.setItem('profileData', JSON.stringify(updatedProfileData))
				
        })

    }, []);

    return (
        <>
            <div className="d-flex justify-content-center align-items-center p-1 mb-3 mx-auto">
                {catId !== "" && <img src={`${config.CAT_IMG_BASE_URI}/${catId}.png`} width="160px" />}
                {desc !== "" && <b className='col-7'>{desc}</b>}
            </div>
            <div className="row d-flex justify-content-center m-1 my-2">
                {
                    SubCategories.map((s) => {
                        return (
                            <>
                                <SubCategoriesCard key={s.serv_sub_category_id} tableName={s.table_name} subCatDetails={s} />
                            </>
                        )
                    })
                }
            </div>

            <Banner />

        </>
    )
}
