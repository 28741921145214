import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { useParams } from 'react-router';

import ProductsCard from "../../components/ProductsCard/ProductsCard";
import config from '../../config';

export default function Products() {

    const navigate = useNavigate();

    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token !== config.TOKEN) {
            navigate("/");
        }
    }, []);

    const [Products, setProducts] = useState([]);
    const { title } = useParams();

    useEffect(() => {

        axios.get(`${config.BASE_API_URI}/GetProduct.php?key=${localStorage.getItem("key")}&title=${title}`).then(res => {
            setProducts(res.data[1]);
        })

    }, []);
	
	 const buttonStyles = {
    fontWeight: 500,
    fontSize: '22px',
	borderRadius: '10px',
    padding: '0px 17px 3px 13px',
  };
  
   const handlePage = () => {
    navigate(-2);
  };


    return (
        <>
            <div className="d-flex justify-content-center align-items-center p-1 mb-3 mx-auto">
                {<img src={`${config.CUSTOM_IMAGES_URL}/samples.png`} width="160px" />}
                {<b className='col-7'>Most easy and quick samples ordering system</b>}
				
            </div>
			<div className='text-end px-4' style={{textAlign: 'end'}}>
					<button className="btn btn-sm btn-primary" onClick={handlePage} style={buttonStyles}>
					&#8249;  back
				   </button>
				</div>
            <div className="row d-flex justify-content-center m-1 my-2">
                {
                    Products.map((s,index) => {
                        return (
                            < div key={index} className="col-md-6 col-sm-6 col-6 col-lg-3 mx-auto ">
                                <ProductsCard ProductDetails={s} />
                            </div>
                        )
                    })
                }
            </div>

           

        </>
    )
}
