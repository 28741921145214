import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router"
import { useParams } from 'react-router';
import Journal from '../../components/Journal/Journal';
import config from '../../config';

import InfiniteScroll from 'react-infinite-scroll-component';															 
export default function Journals() {

    const navigate = useNavigate();

    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token !== config.TOKEN) {
            navigate("/");
        }
    }, []);
    const { journalId } = useParams();
	
	const PAGE = 1;											 
    const [Journals, setJournals] = useState([]);
	const [PageNumber, setPageNumber] = useState(PAGE);

    useEffect(() => {
        let formData = new FormData();
        formData.append("key", localStorage.getItem("key"));
        formData.append("Jid", journalId);
		 formData.append("page", PageNumber);

        axios.post(`${config.BASE_API_URI}/GetJournal.php`, formData).then(res => {
            setJournals([...Journals , ...res.data[1].result]);
        }).catch(err => alert(err));
    }, [PageNumber]);
 const fetchMoreData = () => {
        setPageNumber(prev => prev + 1);
    }

    return (
	<>
        
		<InfiniteScroll
                dataLength={Journals.length}
                next={fetchMoreData}
                hasMore={true}
                loader={<h4 style={{marginLeft:"40px"}}>Loading...</h4>}
            >
		
		
            {
                Journals.map((j, index) => {
                    return (
                        <Journal key={index} Journal={j} />
                    )
                })
            }
		</InfiniteScroll >
        
</>
    )
}
