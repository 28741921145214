import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import Article from '../../components/Article/Article'
import config from '../../config';

import InfiniteScroll from 'react-infinite-scroll-component';

export default function Articles() {

    const navigate = useNavigate();

    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token !== config.TOKEN) {
            navigate("/");
        }
    }, []);

    const PAGE = 1;
    const [Articles, setArticles] = useState([]);
    const [PageNumber, setPageNumber] = useState(PAGE);

    useEffect(() => {
        let formData = new FormData();
        formData.append("key", localStorage.getItem("key"));
        formData.append("page", PageNumber);

        axios.post(`${config.BASE_API_URI}/GetArticles.php`, formData).then(res => {
            setArticles([...Articles, ...res.data[1].result]);
        })
    }, [PageNumber]);

    const fetchMoreData = () => {
        setPageNumber(prev => prev + 1);
    }

    return (
        <>
            <InfiniteScroll
                dataLength={Articles.length}
                next={fetchMoreData}
                hasMore={true}
                loader={<h4 style={{marginLeft:"40px"}}>Loading...</h4>}
            >
                {
                    Articles.map((article, index) => {
                        return (
                            <Article key={index} article={article} />
                        )
                    })
                }
            </InfiniteScroll >
        </>
    )
}
