import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import background from "../../assets/background.png";
import initiative from "../../assets/initiative.png";
import LoginHeader from '../Login/LoginHeader';

const Verify = () => {
    const navigate = useNavigate();

    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token === config.TOKEN) {
            //document.location.href = "/home";
			navigate("/home");
        }
    }, []);

    const [OTP, setOTP] = useState(null);

    const handleVerification = (e) => {
        e.preventDefault();
        const otp = sessionStorage.getItem("otp");
        if (otp === OTP) {
            // get and store profile details in cookies
            let formData = new FormData();
            formData.append("key", localStorage.getItem("key"));

            axios.post(`${config.BASE_API_URI}/login.php`, formData).then(res => {
                localStorage.setItem("profileData", JSON.stringify(res.data[1].result[0]));
                // enable the header & bottom navigation bar
                document.getElementById("hideHeader").style.display = "block";
                document.getElementById("hideBottomNav").style.display = "block";
                // set token after correct otp
                localStorage.setItem("token", config.TOKEN);
                //document.location.href = "/home";
				navigate("/home");
            }).catch(err => {
                alert(err)
            });
        } else {
            alert("Invalid OTP!")
        }
    }

    return (
        <>
        <LoginHeader/>
            <div className="p-3 d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${background})`, height: "88vh", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                <form className="row" onSubmit={handleVerification}>
                    <div className='col-12'>
                        <img src={initiative} width="50%" alt="initiative img" />
                    </div>
                    <div class="form-group col-12 mt-2">
                        
                        <input type="number" class="form-control" placeholder="Enter OTP" required
                            onChange={(e) => setOTP(e.target.value)} />
                    </div>
                    <div className="col-12 text-center">
                        <input type="submit" value="Verify OTP" class="btn btn-primary roundBtn" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default Verify;
