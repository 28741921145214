import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { useParams } from 'react-router';

import ProductsCard from "../../components/ProductsCard/ProductsCard";
import InfiniteScroll from 'react-infinite-scroll-component';
import config from '../../config';

export default function Products() {

    const navigate = useNavigate();

    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token !== config.TOKEN) {
            navigate("/");
        }
    }, []);

    const [Products, setProducts] = useState([]);
    const { searchedString } = useParams();
	
	 const PAGE = 1;
    const [SearchedProduct, setSearchedProduct] = useState([]);
    const [PageNumber, setPageNumber] = useState(PAGE);

    useEffect(() => {
		let formData = new FormData();
        formData.append("key", localStorage.getItem("key"));
        formData.append("search_string", searchedString);
		formData.append("page", PageNumber);

        axios.get(`${config.BASE_API_URI}/Searchmoleculeproducts.php?key=${localStorage.getItem("key")}&searchedString=${searchedString}`).then(res => {
			
            setSearchedProduct(res.data[1].result);
        })

    }, [searchedString, PageNumber]);
	
	 const fetchMoreData = () => {
         setPageNumber(prev => prev + 1);
     }


    return (
        <>
            <InfiniteScroll
                dataLength={SearchedProduct.length}
                next={fetchMoreData}
                hasMore={true}
                loader={<h4 style={{marginLeft:"40px"}}></h4>}
            > 
			<div className="row d-flex justify-content-center m-1 my-2">
            {
                SearchedProduct !== [] ? (SearchedProduct.map((s, index) => {
                    return (
                        < div key={index} className="col-md-6 col-sm-6 col-6 col-lg-3 mx-auto ">
                                <ProductsCard ProductDetails={s} />
                            </div>
                    )
                })) : alert(<p>No Products Found..!</p>)
            }
			</div>
             </InfiniteScroll > 

           

        </>
    )
}
