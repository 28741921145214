import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from "../../assets/company_logo.png";
// import user from "../../assets/icons/user.png";
import "../../components/Header/Header.css";

const LoginHeader = () => {

    const navigate = useNavigate();

    const [SearchString, setSearchString] = useState("");

    const [DrName, setDrName] = useState("");
    useEffect(() => {
        let profileData = localStorage.getItem("profileData");
        let drDetails = JSON.parse(profileData);
        setDrName(drDetails ? drDetails.first_name : "");
    }, []);

    const handleSearch = (e) => {
        e.preventDefault();

        setSearchString(e.target.value);

        if (e.keyCode === 13) {
            document.getElementById("searchBtn").click();
        }
    }

    const doSearch = (e) => {
        e.preventDefault();
        navigate(`/searcharticles/${SearchString}`);
    }

    return (
        <div id="hideHeader">
            <div className="headerContainer">
                <div className="m-4 my-1 d-flex justify-content-between align-items-center">
                    <Link to="/">
                        <img src={logo} alt="logo" width="70%" />
                    </Link>

               
                    <p className='text-center mt-2'>Welcome Dr. Guest
                    </p>

                    {/* <Link to="/profile">
                        <img src={user} alt="user" width="30px" height="30px" className="profileIcon" />
                    </Link> */}
                </div>
            </div>          

        </div>
    );
}

export default LoginHeader;
